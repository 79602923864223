import {
  Autocomplete,
  ButtonBase,
  Grid,
  Grow,
  ListItem,
  Paper,
  Skeleton,
  Typography,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";

import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../components/tables/DataTable";
import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop";
import { StyledSearchBar } from "../../../components/inputs/SearchBar";
import TimeRangeSelector from "../../../components/layouts/common/TimeRangeSelector";
import { getRegionApi } from "../../../apis/region.api";
import { REGIONS_TYPE, VIDEOS_RATING, VIDEOS_TAGS } from "../../../utils/constants";
import AsyncDropDown from "../../../components/inputs/AsyncDropDown";
import { getCampApi } from "../../../apis/camp.api";
import { useEffect } from "react";
import { getEventApi } from "../../../apis/event.api";
import CreatableRegionComponent from "../../../components/custom/CreateableRegionComponent";
import VideoCounts from "./VideoCounts";
import { openModal } from "../../../store/actions/modalAction";
// import ListOfInReviewBeneficiaryController from "../view/ListOfInReviewBeneficiary";
// import ListOfInReviewBeneficiaryUi from "../view/ListOfInReviewBeneficiaryUi";
// import ListOfReviewBeneficiaryModal from "../view/ListOfInReviewBeneficiaryModal";
// import ListOfFinalVideosModal from "../view/ListOfFinalVideosModal";
import { findObjectKeyByValue, titleCase } from "../../../utils/helper";
import { getProductApi } from "../../../apis/product.api";
// import ListOfManagerApproveVideosModal from "../view/ListOfManagerApproveVideosModal";
// import ListOfRejectedVideosModal from "../view/ListOfRejectedVideosModal";
// import ListOfTotalVideosModal from "../view/ListOfTotalVideosModal";
// import ListOfFinalVideosController from "../view/ListOfFinalVideosController";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ListVideoUi = ({
  data,
  setData,
  countsLoading,
  setCountsLoading,
  title,
  filters,
  setFilters,
  list,
  loading,
  columns,
  callback
}) => {

  const dispatch = useDispatch();
  return (
    <>
      <Box component={Paper} p={3} mb={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
          }}
        >
          <FiltersBox mt={3}>
            <PaddingBoxInDesktop
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <TimeRangeSelector
                onChange={(newRange) => {
                  setFilters({ ...filters, ...newRange });
                }}
              />
            </PaddingBoxInDesktop>

            <PaddingBoxInDesktop
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              pl={2}
            >
              <AsyncDropDown
                lazyFun={getCampApi}
                OptionComponent={({ option, ...rest }) => {
                  return <ListItem {...rest}>{option.name}</ListItem>;
                }}
                onChange={async (changedVal) => {
                  setFilters({
                    ...filters,
                    camp: changedVal ? changedVal._id : null,
                  });
                }}
                titleKey={"name"}
                valueKey={"_id"}
                InputComponent={(params) => (
                  <StyledSearchBar
                    placeholder={"Select Camp"}
                    {...params}
                    margin="none"
                  />
                )}
              />
            </PaddingBoxInDesktop>

            <PaddingBoxInDesktop
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              pl={2}
            >
              <AsyncDropDown
                lazyFun={getEventApi}
                OptionComponent={({ option, ...rest }) => {
                  return <ListItem {...rest}>{option.name}</ListItem>;
                }}
                onChange={async (changedVal) => {
                  setFilters({
                    ...filters,
                    donation_event_id: changedVal ? changedVal._id : null,
                  });
                }}
                titleKey={"name"}
                valueKey={"_id"}
                InputComponent={(params) => (
                  <StyledSearchBar
                    placeholder={"Select Event"}
                    {...params}
                    margin="none"
                  />
                )}
              />
            </PaddingBoxInDesktop>
            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }} pl={2}  >
                                <AsyncDropDown


                                    lazyFun={async (para) => await getProductApi({ ...para, allStatus: true })}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, product: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Product"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>
          </FiltersBox>
          <FilterTitleBox>
            <PaddingBoxInDesktop
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <CreatableRegionComponent
                onlyDropDown
                disabled={loading}
                value={
                  typeof filters.country == "object" ? filters.country : null
                }
                label={"Select Country*"}
                type={REGIONS_TYPE.COUNTRY}
                parent={null}
                onChange={async (changedVal) => {
                  setFilters({
                    ...filters,
                    country: changedVal ? changedVal._id : null,
                    state: null,
                    district: null,
                    taluka: null,
                  });
                }}
              />
            </PaddingBoxInDesktop>

            <PaddingBoxInDesktop
              pl={2}
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <CreatableRegionComponent
                onlyDropDown
                disabled={
                  loading || !filters.country || filters.country == null
                }
                value={typeof filters.state == "object" ? filters.state : null}
                type={REGIONS_TYPE.STATE}
                onChange={async (changedVal) => {
                  setFilters({
                    ...filters,
                    state: changedVal ? changedVal._id : null,
                    district: null,
                    taluka: null,
                  });
                }}
                parent={
                  filters.country && typeof filters.country == "object"
                    ? filters.country["_id"]
                    : filters.country
                }
                label={"Select State*"}
              />
            </PaddingBoxInDesktop>

            <PaddingBoxInDesktop
              pl={2}
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <CreatableRegionComponent
                onlyDropDown
                disabled={loading || !filters.state || filters.state == null}
                value={
                  typeof filters.district == "object" ? filters.district : null
                }
                type={REGIONS_TYPE.DISTRICT}
                onChange={async (changedVal) => {
                  setFilters({
                    ...filters,
                    district: changedVal ? changedVal._id : null,
                    taluka: null,
                  });
                }}
                label={"Select District*"}
                parent={
                  filters.state && typeof filters.state == "object"
                    ? filters.state["_id"]
                    : filters.state
                }
              />
            </PaddingBoxInDesktop>

            <PaddingBoxInDesktop
              pl={2}
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <CreatableRegionComponent
                onlyDropDown
                disabled={
                  loading || !filters.district || filters.district == null
                }
                value={
                  typeof filters.taluka == "object" ? filters.taluka : null
                }
                type={REGIONS_TYPE.TALUKA}
                onChange={async (changedVal) => {
                  setFilters({
                    ...filters,
                    taluka: changedVal ? changedVal._id : null,
                  });
                }}
                label={"Select Taluka*"}
                parent={
                  filters.district && typeof filters.district == "object"
                    ? filters.district["_id"]
                    : filters.district
                }
              />
            </PaddingBoxInDesktop>
          </FilterTitleBox>
          <FilterTitleBox>
          <PaddingBoxInDesktop
            mb={2}
            sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
          >
            <Autocomplete
              disableClearable
              // value={findObjectKeyByValue(filters.tags, VIDEOS_TAGS)}
              onChange={(e, changedVal) => {
                setFilters({
                  ...filters,
                  tags: changedVal ? changedVal._id : null,
                });
              }}
              options={[
                { label: "All", _id: null },
                ...Object.keys(VIDEOS_TAGS).map((key) => ({
                  label: titleCase(key),
                  _id: VIDEOS_TAGS[key],
                })),
              ]}
              sx={{
                width: "100%",
                display: "flex",
                "*": { display: "flex", justifyContent: "center" },
              }}
              renderInput={(params) => (
                <StyledSearchBar
                  placeholder={"Select Video Tags"}
                  {...params}
                  // size={size}
                />
              )}
            />
          </PaddingBoxInDesktop>
          <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} pl={2}>
            <Autocomplete
              disableClearable
              value={findObjectKeyByValue(filters.ratings, VIDEOS_RATING)}
              onChange={(e, changedVal) => {
                setFilters({
                  ...filters,
                  ratings: changedVal ? changedVal._id : null,
                });
              }}
              options={[
                { label: "All", _id: null },
                ...Object.keys(VIDEOS_RATING).map((key) => ({
                  label: titleCase(key),
                  _id: VIDEOS_RATING[key],
                })),
              ]}
              sx={{
                width: "100%",
                display: "flex",
                "*": { display: "flex", justifyContent: "center" },
              }}
              renderInput={(params) => (
                <StyledSearchBar placeholder="Select Rating" {...params} />
              )}
            />
          </PaddingBoxInDesktop>
          </FilterTitleBox>
          <VideoCounts setFilters={setFilters} filters={filters} data={data} countsLoading={countsLoading} callback={callback}/>
          {/* <Grid container spacing={2}>
            <VideoCounts
              count={count}
              loading={loading}
              onFinalVideosClicks={onFinalVideosClicks}
              onInDirectVideosClicks={onInDirectVideosClicks}
              onApproveVideosClicks={onApproveVideosClicks}
              onRejectVideosClicks={onRejectVideosClicks}
              onTotalVideosClicks={onTotalVideosClicks}
            />
          </Grid> */}
        </Box>
      </Box>

      <Box mb={3}>
        <Paper elevation={2} sx={{ width: "100%", padding: 4 }}>
          <Box mb={4}>
            <FiltersBox>
              <PaddingBoxInDesktop
                sx={{
                  display: "flex",
                  flex: 1,
                  "*": {
                    justifyContent: "center",
                  },
                }}
              >
                <Typography variant="h5" mb={2}>
                  {title}
                </Typography>
              </PaddingBoxInDesktop>
              <PaddingBoxInDesktop
                sx={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                  "*": {
                    justifyContent: "center",
                  },
                }}
                pl={3}
              >
                <StyledSearchBar
                  fullWidth
                  title="Search By  Name | Phone No."
                  size="small"
                  placeholder={"Search By  Name | Phone No. "}
                  value={filters.search}
                  onChange={(e) => {
                    setFilters({ ...filters, search: e.target.value });
                  }}
                />
              </PaddingBoxInDesktop>
            </FiltersBox>
          </Box>
         
          <Box sx={{ minHeight: "300px" }} className="table">
            <DataTable
              columns={columns}
              rows={list.result ? list.result : []}
              count={list.total ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default ListVideoUi;
