import { OpenInNew } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Paper,
  ListItem,
  Typography,
  styled,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SubmitButton from "../../../components/button/SubmitButton";
import {
  StyledSearchBar,
  UserSearchBarNormal,
} from "../../../components/inputs/SearchBar";

import { REGIONS_TYPE, USER_ROLES } from "../../../utils/constants";
import DataTable from "../../../components/tables/DataTable";
import {
  findObjectKeyByValue,
  titleCase,
  toTitleCase,
} from "../../../utils/helper";
import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop";
import AsyncDropDown from "../../../components/inputs/AsyncDropDown";
import { getRegionApi } from "../../../apis/region.api";
import { getCampApi } from "../../../apis/camp.api";
import { BENIFICIARY_STATUS } from "../../../utils/beneficiary.constant";
import TimeRangeSelector from "../../../components/layouts/common/TimeRangeSelector";
import { getProductApi } from "../../../apis/product.api";
import AsynSearchBar from "../../../components/inputs/AsynSearchBar";
import { getEventApi } from "../../../apis/event.api";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import { useTheme } from "@emotion/react";
import { CenteredBox } from "../../../components/layouts/OneViewBox";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ListBeneficiaryUi = ({
  countF1,
  countF6,
  countF8,
  title,
  modalKey,
  filters,
  setFilters,
  list,
  exportLoading,
  loading,
  onExportBtnClick,
  columns,
  callBackFun,
}) => {
  const user = useSelector((state) => state.user);
  const theme = useTheme()
  const data = list.length;
  return (
    <>
      <CustomDialog
        id={modalKey}
        loading={loading}
        err={filters.err}
        // onSubmit={onSubmit}
        title={title}
        // onClose={onClose}
        // disableDirectClose={false}
        closeText={"Close"}
        // confirmText={"Update"}
      >
        {loading ? <CenteredBox sx={{ minHeight: "200px" }}><CircularProgress /></CenteredBox> :
        
        <Box mb={3}>
          {/* <Paper elevation={2} sx={{ width: "100%", padding: 4 }}> */}
          <Box mb={3}>
          </Box>

          <FiltersBox mb={3}>
            <PaddingBoxInDesktop
              mb={2}
              sx={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
                "*": {
                  justifyContent: "center",
                },
              }}
              pl={2}
            >
              <AsynSearchBar
                fullWidth
                title="Search By  Name "
                size="small"
                placeholder={"Search By  Name"}
                defaultValue={filters.search}
                onChange={(changedVal) => {
                  setFilters({ ...filters, search: changedVal });
                }}
              />
                </PaddingBoxInDesktop>
            <Box></Box>
          </FiltersBox>
          <Box sx={{ minHeight: "300px" }} className="table">
            <DataTable
              columns={columns}
              rows={list ? list : []}
              count={data ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
              noPagination
            />
          </Box>
         
           <TableContainer sx={(theme) => ({ border: "1px solid " + theme.palette.grey.main,backgroundColor:theme.palette.grey.main, borderRadius: theme.shape.borderRadius * 0.5,marginTop:"10px" })} >
          <Table size="medium" sx={{ height: "100%" }}>
            <TableRow>
              <TableCell sx={{align: "left", minWidth: "150px",}}>
                Total Counts
              </TableCell>
              <TableCell sx={{align: "left", minWidth: "200px",}}>
                {countF1}
              </TableCell>
              <TableCell sx={{align: "left", minWidth: "200px",}}>
              {countF6}
              </TableCell>
              <TableCell sx={{align: "left", minWidth: "200px",}}>
                {countF8}
              </TableCell>
            </TableRow>
            </Table>
            </TableContainer>
          {/* </Paper> */}
        </Box>
}
      </CustomDialog>
    </>
  );
};
export default ListBeneficiaryUi;
