import { memo, useCallback, useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";

import { closeModal, openModal } from "../../store/actions/modalAction";

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import {
  Add,
  AddCircle,
  AddRounded,
  CheckCircle,
  Delete,
  Edit,
  Info,
  Person,
  VideoCameraFrontRounded,
  VideocamOffRounded,
} from "@mui/icons-material";

import moment from "moment";

import BeneficiaryDetailedViewController from "./../beneficiary/detailedview/DetailedViewController";

import {
  BENIFICIARY_STATUS,
  EVENT_STATUS,
  FOLLOWUP_TYPE,
  FOLLOW_UP_STATUS,
} from "../../utils/beneficiary.constant";

import { deleteFollowupApi, getFollowUpApi } from "../../apis/followup.api";
import { Link } from "react-router-dom";
import FollowupDetailedViewController from "./FollowupDetailedViewController";
import UpdateFollowupStatusController from "./UpdateFollowupStatusController";
import MODULES from "../../utils/module.constant";
import { USER_ROLES } from "../../utils/constants";
import MessageDilog from "../../components/MessageDilog";

const ActionComponent = memo(({ params, setParams, modalKey, type }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);
  const allowedModules = user.data.modules ?? [];

  const [loading, setLoading] = useState(false);

  const onBenificaryClick = () => {
    dispatch(
      openModal(
        <BeneficiaryDetailedViewController id={params.beneficiary_id} />,
        "md",
        false,
        "beneficiary-detailed-view"
      )
    );
  };

  const onViewMoreClick = () => {
    dispatch(
      openModal(
        <FollowupDetailedViewController id={params._id} />,
        "md",
        false,
        "followup-detailed-view"
      )
    );
  };
  const onUpdateClick = () => {
    dispatch(
      openModal(
        <UpdateFollowupStatusController
          params={params}
          id={params._id}
          type={type}
          callBack={(val) => {
            setParams({ ...params, ...val });
          }}
        />,
        "sm",
        false,
        "followup-update"
      )
    );
  };

  const onDelete = () => {
    dispatch(
      openModal(
        <MessageDilog
          title="Alert!"
          message={"Are you sure to delete " + params.name + "?"}
          onSubmit={(e) => {
            e.preventDefault();
            setLoading(true);
            dispatch(
              callApiAction(
                async () => await deleteFollowupApi({ id: params._id }),
                (response) => {
                  setParams({});
                  setLoading(false);
                  dispatch(closeModal("delete"));
                },
                (err) => {
                  setLoading(false);
                }
              )
            );
          }}
        />,
        "sm",
        false,
        "delete"
      )
    );
  };

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      <Tooltip title="Followup Info">
        <IconButton size="inherit" onClick={onViewMoreClick}>
          <Info color="info" fontSize="inherit" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Beneficiary Info">
        <IconButton size="inherit" onClick={onBenificaryClick}>
          <Person color="info" fontSize="inherit" />
        </IconButton>
      </Tooltip>

      {params.status == FOLLOW_UP_STATUS.PENDING &&
        (user.data.role == USER_ROLES.ADMIN ||
          (user.data.role != USER_ROLES.ADMIN &&
            params.last_followup_no == params.followup_no - 1) ||
          (user.data.role != USER_ROLES.ADMIN &&
            type == FOLLOWUP_TYPE.INCLUSIVE)) &&
        allowedModules.includes(MODULES.UPDATE_FOLLOWUP) && (
          <Tooltip title="Update FollowUp">
            <IconButton size="inherit" onClick={onUpdateClick}>
              <Add color="success" fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}

      <Tooltip title="Followup Delete">
        <IconButton size="inherit" onClick={onDelete}>
          <Delete color="error" fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </Box>
  );
});

const ListController = ({ type }) => {
  const dispatch = useDispatch();

  const title = "Followups ";
  const modalKey = "followup";
  const fetchApi = getFollowUpApi;

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "scheduled_date",
        label: "Scheduled Date",
        minWidth: "100px",
        align: "left",
        sort: true,
        renderValue: (params, setParams) =>
          moment(params.scheduled_date).format("DD/MM/YYYY"),
      },
      {
        id: 6,
        fieldName: "followedup_date",
        label: "Followup Date",
        minWidth: "100px",
        align: "left",
        sort: true,
        renderValue: (params, setParams) =>
          params.followedup_date
            ? moment(params.followedup_date).format("DD/MM/YYYY")
            : "NA",
      },
      { id: 2, fieldName: "name", label: " Name", align: "left", sort: true },

      {
        id: 3,
        fieldName: "phone",
        label: " Contact",
        align: "left",
        renderValue: (params, setParams) => (
          <Link to={"tel:" + params.phone} target="_blank">
            {params.phone}
          </Link>
        ),
      },
      { id: 6, fieldName: "last_remarks", label: " Remarks", align: "left" },
      {
        id: 4,
        fieldName: "followup_no",
        label: " Followup No.",
        align: "left",
        sort: true,
      },
      {
        id: 5,
        fieldName: "videos",
        label: "Videos Uploaded",
        align: "left",
        sort: true,
        renderValue: (params, setParams) =>
          params?.videos[0]?.url ? (
            <VideoCameraFrontRounded color="info" />
          ) : (
            <VideocamOffRounded color="error" />
          ),
      },

      {
        id: 5,
        fieldName: "",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            type={type}
            modalKey={modalKey}
            params={params}
            setParams={setParams}
          />
        ),
      },
    ],
    [dispatch, type]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["name"],
    sort: "scheduled_date",
    taluka: null,
    camp: null,
    event: null,
    type,
    tab: 1,
    sortDirection: 1,
    status: FOLLOW_UP_STATUS.PENDING,
    videos: [] ? false : true,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchTimes = [
    {
      startDate: null,
      endDate: null,
    },
    {
      startDate: moment().startOf("day").valueOf(),
      endDate: moment().endOf("day").valueOf(),
    },
    {
      startDate: null,
      endDate: moment().startOf("day").valueOf(),
    },

    {
      startDate: moment().add(1, "days").startOf("day").valueOf(),
      endDate: moment().add(1, "days").endOf("day").valueOf(),
    },
    {
      startDate: moment().endOf("day").valueOf(),
      endDate: null,
    },
  ];

  const fetchList = () => {
    setLoading(true);
    const times = fetchTimes[filters.tab];
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters, ...times }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;
