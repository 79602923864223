import { Box, Paper, Skeleton, Typography, useTheme } from "@mui/material";
import { Bar } from "react-chartjs-2";

const AnalysisOfInclusionState = ({ list, loading }) => {

    let avgi1i2 = 0;
    let avgi2i3 = 0
    // let avgs3s4 = 0
    // console.log("llss",list?.analaysissearingAndSpeecsState[0])
    if (list && Array.isArray(list) && list?.length > 0) {
        for (let item of list) {
            avgi1i2 = item.avgi1i2
            avgi2i3 = item.avgi2i3
            // avgi3i4 = item.avgi3i4
        }
    }
    const theme = useTheme();
    const labels = ["I1 to I2", "I2 to I3"];
    const options = {
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                display: true,
                color: "black",
                formatter: Math.round,
                anchor: "end",
                offset: -20,
                align: "start"
            }
        },
        scales: {
            x: {
                grid: { display: "" }
            },
            y: {
                min: 0,
                grid: { display: "" }
            }
        }
    };

    const data = {
        labels,
        datasets: [
            {
                label: "Inclusion Test",
                data:[Math.round(avgi1i2),Math.round(avgi2i3)],
                // data: [list?.averageS1ToS2, list?.averageS2ToS3, list?.averageS3ToS4],
                // data: [list?.analaysisHearingAndSpeechState[0]?.avgs1s2, list?.analaysisHearingAndSpeechState[0]?.avgs2s3, list?.analaysisHearingAndSpeechState[0]?.avgs3s4],
                backgroundColor: theme.palette.primary.main,
                barPercentage: 1,
                base: 0
            }
        ]
    };

    if (loading) {
        return (
            <Paper component={Box} p={2}>
                <Skeleton variant="rounded" height="300px" width="100%" />
            </Paper>
        );
    }



    return (
        <Paper component={Box} p={3}>
            <Typography>Analysis Of Inclusion Test</Typography>
            <Box mt={1} sx={{ minHeight: "381px" }} className="table">
                <Bar options={options} data={data} style={{ height: "100%" }} />
            </Box>
        </Paper>
    );
};

export default AnalysisOfInclusionState;
