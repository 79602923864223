import { downloadBeneficiaryReport } from "./beneficiary.api";

const mode = "production"
let domain = "";


switch (mode) {
  case "local":
    domain = "http://localhost:8001/";
    break;
  case "ip":
    domain = "http://10.10.81.35:8001/";
    break;
  case "development":
    domain = "https://social-impact-backend-development.up.railway.app/";
    break;
  case "production":
    domain = "https://social-impact-backend-production.up.railway.app/";
    break;

  default:
    domain = "/";
}

const endpoints = {
  root: domain,
  fileImage: `${domain}api/file/image`,
  fileFile: `${domain}api/file/file`,
  fileBase: `${domain}api/file`,
  notificationFetch: `${domain}api/notification`,
  signIn: `${domain}api/auth/login`,
  resetToken: `${domain}api/auth/reset-token`,
  resetPassword: `${domain}api/auth/reset-password`,

  userAndSystemFetchByToken: `${domain}api/user/fetch-by-token`,
  userBase: `${domain}api/user/`,
  userSignature: `${domain}api/user/signature`,
  teacherBase: `${domain}api/user/teacher/`,
  donorBase: `${domain}api/user/donor/`,
  ngoBase: `${domain}api/user/ngo/`,
  govBase: `${domain}api/user/gov/`,



  userPassword: `${domain}api/user/reset-password`,



  secretsBase: `${domain}api/secrets/`,
  secretsById: `${domain}api/secrets/by-id`,


  userById: `${domain}api/user/fetch-by-id`,
  documentsBase: `${domain}api/docs`,

  region: `${domain}api/region`,
  regionById: `${domain}api/region/fetch-by-id`,
  product: `${domain}api/product`,
  productById:`${domain}api/product/by-id`,
  campBaseUrl: `${domain}api/camp`,
  campReportBaseUrl: `${domain}api/camp/report`,
  campStaffBaseUrl: `${domain}api/camp/staff`,
  campImagesBaseUrl: `${domain}api/camp/image`,
  campCloseUrl: `${domain}api/camp/close`,
  campByIdUrl: `${domain}api/camp/id`,
  dashboardUrl: `${domain}api/dashboard/`,
  hearingstateUrl: `${domain}api/dashboard/hearing-state`,
  speechstateUrl: `${domain}api/dashboard/speech-state`,

  beneficiaryBaseUrl: `${domain}api/beneficiary`,
  beneficiaryByIdBaseUrl: `${domain}api/beneficiary/id`,
  beneficiaryPdfReportBaseUrl: `${domain}api/beneficiary/pdf`,
  beneficiaryDemoSheetUrl: `${domain}api/beneficiary/demo-sheet`,

  eventBaseUrl: `${domain}api/event`,
  eventByIdBaseUrl: `${domain}api/event/by-id`,
  addBeneficiaryToEvent: `${domain}api/event/add-beneficiary`,
  removeBeneficiaryToEvent: `${domain}api/event/remove-beneficiary`,
  closeEvent: `${domain}api/event/close-event`,
  addImagesToEvent: `${domain}api/event/add-images`,
  regionbyeventUrl: `${domain}api/event/region-by-event`,

  followupBaseUrl: `${domain}api/followup`,

  dashBoardFollowup: `${domain}api/followup/dashboard`,
  averageListUrl: `${domain}api/followup/avg-list`,
  inclusiveFollowup: `${domain}api/followup/inclusive`,
  followupByBeneficiary: `${domain}api/followup/by-beneficiary`,
  followupByIdBaseUrl: `${domain}api/followup/by-id`,
  followupVideo: `${domain}api/followup/video`,
  videoStatus: `${domain}api/beneficiary/video-status`,

  validateBeneficiaryBaseUrl: `${domain}api/beneficiary/validate`,
  beneficiaryCountOfDonation: `${domain}api/beneficiary/counts-of-donation`,
  beneficiaryCountByRegion: `${domain}api/beneficiary/counts-by-region`,
  videoAvailableBeneficiaryUrl: `${domain}api/beneficiary/video`,
  videoAvailableCountBeneficiaryUrl: `${domain}api/beneficiary/video-count`,
  videoList: `${domain}api/beneficiary/video-only`,
  videoRatings: `${domain}api/beneficiary/video-ratings`,
  videoRatingAndTags: `${domain}api/beneficiary/video-ratings-tags`,
  videoUrls: `${domain}api/beneficiary/video-url`,
  fetchVideos: `${domain}api/beneficiary/fetch-video`,

  videosCount:`${domain}api/beneficiary/videos-count`,

  product: `${domain}api/product`,
  inReviewVideos:`${domain}api/beneficiary/inreview-videos`,
  finalVideos:`${domain}api/beneficiary/final-videos`,
  rejectVideos:`${domain}api/beneficiary/rejected-videos`,
  managerVideos:`${domain}api/beneficiary/manager-videos`,
  totalVideos:`${domain}api/beneficiary/total-videos`,
  updateVideos:`${domain}api/beneficiary/update-videos`,
  eventsVideos:`${domain}api/followup/events`,

  eventsWiseBeneficiaryVideos:`${domain}api/event/video-events`,
  totalPreAndPostVideos:`${domain}api/beneficiary/fetch-prevideos`,

  fetchPreVideos:`${domain}api/beneficiary/fetch-total-prevideos`,
  keydocumentsBase:`${domain}api/key-document/`,
  keydocumentById:`${domain}api/key-document/by-id`,

  eventWiseVideoMatrixCount:`${domain}api/followup/fetch-event-wise-videos`,

  downloadBeneficiaryReportByDistrict:`${domain}api/beneficiary/download-pdf-district-wise`,

  deleteFollowup:`${domain}api/followup/delete`
};


export default endpoints