import { Chip } from "@mui/material";
import { USER_ROLES } from "./constants";

export const accessToken = {
  set: (val) => {
    localStorage.setItem("bdms_access_token", val);
  },
  get: () => localStorage.getItem("bdms_access_token"),
  remove: () => {
    localStorage.removeItem("bdms_access_token");
  },
};
export const loggedInUser = {
  set: (val) => {
    localStorage.setItem("bdms_user", JSON.stringify(val));
  },
  get: () =>
    localStorage.getItem("bdms_user")
      ? JSON.parse(localStorage.getItem("bdms_user"))
      : null,
  remove: () => {
    localStorage.removeItem("bdms_user");
  },
};

export const refreshToken = {
  set: (val) => {
    localStorage.setItem("bdms_refresh_token", val);
  },
  get: () => localStorage.getItem("bdms_refresh_token"),
  remove: () => {
    localStorage.removeItem("bdms_refresh_token");
  },
};

export const lastReadNotification = {
  set: (val) => {
    localStorage.setItem("last_notification", val);
  },
  get: () =>
    localStorage.getItem("last_notification")
      ? localStorage.getItem("last_notification")
      : null,
  remove: () => {
    localStorage.removeItem("last_notification");
  },
};
export const beneficiaryFormData = {
  set: (key, val) => {
    localStorage.setItem(
      "sims_beneficiary_" + key,
      JSON.stringify(val) === undefined ? null : JSON.stringify(val)
    );
  },
  get: (key) =>
    localStorage.getItem("sims_beneficiary_" + key) !== undefined
      ? JSON.parse(localStorage.getItem("sims_beneficiary_" + key))
      : null,
  remove: (key) => {
    localStorage.removeItem("sims_beneficiary_" + key);
  },
};
export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const logOut = () => {
  refreshToken.remove();
  accessToken.remove();
  loggedInUser.remove();
  lastReadNotification.remove();
};

export const getHeaders = () => {
  const token = `Bearer ${accessToken.get()}`;
  return {
    Authorization: token,
  };
};
export const getFileHeaders = () => {
  const token = `Bearer ${accessToken.get()}`;
  return {
    Authorization: token,
    "Content-Type": "multipart/form-data",
  };
};
export function setZeroPrefix(val) {
  if (parseInt(val, 10) < 10) {
    return `0${val}`;
  }
  return val;
}

export const dateConverter = ({ type = "DD_MM_YYYY", value }) => {
  if (type === "DD_MM_YYYY") {
    const dateObj = new Date(value);
    return `${setZeroPrefix(dateObj.getDate())}/${setZeroPrefix(
      dateObj.getMonth() + 1
    )}/${dateObj.getFullYear()}`;
  }
  return value;
};
export const formatNumberCustomPattern = (number) => {
  return number?.toString()?.replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
};

export const validateEmail = (email) => {
  var re =
    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(email);
};
export const validatePhone = (phone) => {
  console.log("Phone", phone, typeof phone);
  if (!phone) return false;
  return phone.toString().match(/^\d{10}$/) !== null;
};
export const validateaadhar = (aadhar) => {
  var re = /^\d{12}$/;
  return aadhar.match(re);
};
export const allowedDateFilters = [
  {
    id: "today",
    label: "Today",
  },
  {
    id: "yesterday",
    label: "Yesterday",
  },
  {
    id: "last_week",
    label: "Last Week",
  },
  {
    id: "last_month",
    label: "Last Month",
  },
  {
    id: "last_year",
    label: "Last Year",
  },
  {
    id: "this_week",
    label: "This Week",
  },

  {
    id: "this_month",
    label: "This Month",
  },

  {
    id: "this_year",
    label: "This Year",
  },
];
export const getDateFiltersTime = (value) => {
  let date = new Date();
  let startDate = date.getTime();
  let endDate = date.getTime();

  switch (value) {
    case "this_week":
      {
        const currentDay = date.getDay();
        startDate = startDate - currentDay * 3600 * 1000 * 24;
        const temp = new Date(startDate);
        startDate = new Date(
          temp.getFullYear(),
          temp.getMonth(),
          temp.getDate()
        ).getTime();
      }
      break;
    case "this_month":
      {
        const temp = new Date(startDate);
        startDate = new Date(temp.getFullYear(), temp.getMonth()).getTime();
      }
      break;
    case "this_year":
      {
        const temp = new Date(startDate);
        startDate = new Date(temp.getFullYear(), 0).getTime();
      }
      break;

    case "last_month":
      {
        const temp = new Date(startDate);
        startDate = new Date(temp.getFullYear(), temp.getMonth() - 1).getTime();
        endDate = new Date(temp.getFullYear(), temp.getMonth()).getTime();
      }
      break;
    // case "last_year":
    //   {
    //     const temp = new Date(startDate);
    //     startDate = new Date(temp.getFullYear() - 1).getTime();
    //     endDate = new Date(temp.getFullYear()).getTime();
    //   }
    //   break;
    case "last_year":
      {
        const currentDate = new Date();
        startDate = new Date(currentDate.getFullYear() - 1, 0, 1).getTime();
        endDate = new Date(currentDate.getFullYear() - 1, 11, 31, 23, 59, 59, 999).getTime();
      }
      break;

    case "last_week":
      {
        const currentDay = date.getDay();
        endDate = endDate - currentDay * 3600 * 1000 * 24;
        startDate = endDate - 7 * 3600 * 1000 * 24;
        const temp = new Date(endDate);
        const tempStart = new Date(startDate);
        endDate = new Date(
          temp.getFullYear(),
          temp.getMonth(),
          temp.getDate()
        ).getTime();
        startDate = new Date(
          tempStart.getFullYear(),
          tempStart.getMonth(),
          tempStart.getDate()
        ).getTime();
      }
      break;
    case "today":
      {
        startDate = new Date(startDate);

        startDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        ).getTime();

        endDate = startDate + 1000 * 60 * 60 * 24;
      }
      break;
    case "yesterday":
      {
        startDate = startDate - 1000 * 60 * 60 * 24;
        startDate = new Date(startDate);
        startDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        ).getTime();
        endDate = new Date(endDate);
        endDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate()
        ).getTime();
      }
      break;
    case "past":
      {
        endDate = new Date(endDate);

        endDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate() + 1
        ).getTime();

        startDate = null;
      }
      break;
    case "future":
      {
        startDate = new Date(startDate);

        startDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        ).getTime();

        endDate = null;
      }
      break;

    default: {
      const noOfDays = parseInt(value);

      // startDate = startDate - noOfDays * 3600 * 1000 * 24
      // const temp = new Date(startDate)
      // startDate = new Date(
      //   temp.getFullYear(),
      //   temp.getMonth(),
      //   temp.getDate()
      // ).getTime()
      startDate = null;
      endDate = null;
    }
  }

  return {
    startDate,
    endDate,
  };
};

export const createBadge = (badgeStatus) => {
  let color = "default";

  return <Chip label={badgeStatus} color={color}></Chip>;
};

export const titleCase = (s = "") => {
  if (s)
    return s
      .toLowerCase()
      .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
      .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase());

  return null;
};

export const findNameByRole = (role) => {
  for (let val in USER_ROLES) {
    if (USER_ROLES[val] == role) {
      return titleCase(val);
      break;
    }
  }
  return undefined;
};
export const findObjectKeyByValue = (value, object) => {
  for (let val in object) {
    if (object[val] == value) {
      return titleCase(val);
      break;
    }
  }
  return undefined;
};

export const fetchFileName = (str) => {
  if (str) {
    const split = str.split("/");
    return split[split.length - 1];
  }
  return "";
};

/// editional helpers

export const getAllowedRoles = (role) => {
  switch (role) {
    case USER_ROLES.ADMIN:
      return Object.keys(USER_ROLES);
    case USER_ROLES.REGISTRATION_TEAM:
      return [findObjectKeyByValue(USER_ROLES.TEACHER, USER_ROLES)];
    case USER_ROLES.EVENT_TEAM:
      return [
        findObjectKeyByValue(USER_ROLES.DONOR, USER_ROLES),
        findObjectKeyByValue(USER_ROLES.NGO, USER_ROLES),
      ];
    case USER_ROLES.SIT_MANAGER:
      return [
        findObjectKeyByValue(USER_ROLES.SIT_TEAM, USER_ROLES),
        findObjectKeyByValue(USER_ROLES.SIT_TEAM, USER_ROLES),
      ];

    default:
      return [];
  }
};

export const getNewUniqueElements = (previousArray, currentArray) => {
  const previousIds = new Set(previousArray.map((obj) => obj._id));

  const newUniqueElements = currentArray.filter(
    (obj) => !previousIds.has(obj._id)
  );

  return newUniqueElements;
};

export const getUniqueElements = (arr) => {
  const uniqueElements = {};

  arr.forEach((obj) => {
    const id = obj._id;
    uniqueElements[id] = obj;
  });

  const uniqueArray = Object.values(uniqueElements);

  return uniqueArray;
};

export const createFullName = (surname, name, father_name) => {
  return name + " " + (surname ?? "");
};
export const customizeVideoUrl = (val) => {
  if (val && val.search("https://youtu.be/") != -1) {
    const code = val.split("/")[val.split("/").length - 1];
    return "https://www.youtube.com/embed/" + code;
  }
  return val;
};
