import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  ListItem,
  Paper,
  styled,
} from "@mui/material";
// import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop";
import { findObjectKeyByValue, titleCase } from "../../utils/helper";
import { REGIONS_TYPE, USER_ROLES } from "../../utils/constants";
import { StyledSearchBar } from "../../components/inputs/SearchBar";
import { getRegionApi } from "../../apis/region.api";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector";
import HearingStateChartUi from "./HearingStateChartUi";
import SpeechStateChartUi from "./SpeechStateChartUi";
import HearableCounts from "./HearableCounts";
import Counts from "./Counts";
import RegionsCounts from "./RegionsCount";
import DonatedDeviceCOunts from "./DonatedDeviceCounts";
import { getDonorsApi, getNgosApi } from "../../apis/user.api";
import { useDispatch, useSelector } from "react-redux";
import MODULES from "../../utils/module.constant";
import { openModal } from "../../store/actions/modalAction";
import VideoListModal from "../videos/VideoListModal";
import IndiaMap from "./IndiaMap";
import InclusionStateChartUi from "./InclusionStateChartUi";
import { useNavigate } from "react-router-dom";
import ListOfDonationController from "../beneficiary/view/ListOfDonationController";
import ListOfDonationModal from "../beneficiary/view/ListOfDonationModal";
import ListController from "../followup/list/ListController";
import ListOnAvgController from "./ListOnAvgController";
import { getEventApi } from "../../apis/event.api";
import html2canvas from "html2canvas";
import TotalHearingImpairedCounts from "../dashboard/TotalHearingImpairedCounts";
import { jsPDF } from "jspdf";
import { getProductApi } from "../../apis/product.api";
import AnalysisOfDonatedCountsController from "./AnalysisOfDonatedCounts";
import AnalysisOfHearingTest from "./AnalysisOfHearingTest";
import AnalysisOfSpeechTest from "./AnalysisOfSpeechTest";
import AnalysisOfInclusionState from "./AnalysisOfInclusionState";
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const PaddingBoxInDesktop = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    paddingLeft: theme.spacing(3),
  },
}))

const DashboardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#e5e5e5", // Set the desired background color for the dashboard
  padding: theme.spacing(3),
}));

const DashboardUi = ({ filters, setFilters, list, loading }) => {
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allowedModules = user.data.modules ?? [];

  const onVideosClick = () => {
    dispatch(
      openModal(
        <VideoListModal
          defaultFilters={{
            ...filters,
            sort: "videos.ratings",
            sortDirection: -1,
          }}
        />,
        "md",
        false,
        "video-list"
      )
    );
  };

  const onBeneficiaryRedirectsClicks = (e, nuResponse = null, donated = null) => {
    dispatch(
      openModal(
        <ListOfDonationModal donated={donated} nuResponse={nuResponse} />,
        "md",
        false,
        "beneficiary-donation-list"
      )
    );
  };

  const onFollowupsClicks = () => {
    dispatch(
      openModal(
        <ListController defaultFilters={{ ...filters }} />,
        "md",
        false,
        "followup-list"
      )
    );
  };

  const onAvgUsageClick = () => {
    dispatch(
      openModal(
        <ListOnAvgController defaultFilters={{ ...filters }} />,
        "md",
        false,
        "avg-list"
      )
    );
  };

  const captureScreenshot = () => {
    const element = document.getElementById("dashboard-content");

    html2canvas(element, {
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight,
      scale: 2,
      useCORS: true,
      ignoreElements: (node) => {
        const filterBox = document.getElementById("filter-box");
        const mapContainer = document.getElementById("map-container");
        if (filterBox && (node === filterBox || filterBox.contains(node))) {
          return true;
        }
        if (mapContainer && (node === mapContainer || mapContainer.contains(node))) {
          return true;
        }
        return false;
      },
    }).then((canvas) => {
      const pdf = new jsPDF("p", "pt", "a4");
      const imgData = canvas.toDataURL("image/png");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      const now = new Date();
      const timestamp = now.toLocaleString();

      pdf.setFontSize(10);
      pdf.text(`Generated on: ${timestamp}`, 20, 20);
      pdf.addImage(imgData, "PNG", 0, 40, pdfWidth, pdfHeight - 40);
      pdf.save("dashboard-screenshot.pdf");
    });
  };


  return (
    <>
      <DashboardContainer mb={3} id="dashboard-content">
        {user.data.role !== USER_ROLES.TEACHER && (
          <Paper component={Box} p={2}>
            <FiltersBox id="filter-box">
            <PaddingBoxInDesktop mb={1} mt={1} pr={3} sx={{ display: "flex", flex: 1, justifyContent: "flex-end",  "*": {
                    justifyContent: "center",
                  }, }}>
                            <TimeRangeSelector onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} />
                        </PaddingBoxInDesktop>

              <PaddingBoxInDesktop
                mb={1}
                mt={1}
                sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                <Autocomplete
                  disableClearable
                  value={
                    filters.type
                      ? findObjectKeyByValue(filters.type, REGIONS_TYPE)
                      : null
                  }
                  onChange={(e, newVal) => {
                    setFilters({
                      ...filters,
                      type: newVal ? newVal._id : null,
                      country: null,
                      state: null,
                      district: null,
                      taluka: null,
                    });
                  }}
                  options={[
                    ...Object.keys(REGIONS_TYPE).map((key) => ({
                      label: titleCase(key),
                      _id: REGIONS_TYPE[key],
                    })),
                  ]}
                  sx={{
                    width: "100%",
                    display: "flex",
                    "*": { display: "flex", justifyContent: "center", flex: 1 },
                  }}
                  renderInput={(params) => (
                    <StyledSearchBar
                      placeholder="Select Region Type*"
                      {...params}
                    />
                  )}
                />
              </PaddingBoxInDesktop>
              <PaddingBoxInDesktop
                mb={1}
                mt={1}
                sx={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                  "*": {
                    justifyContent: "center",
                  },
                }}
                pl={3}
              >
                <AsyncDropDown
                  key={filters.type}
                  lazyFun={async (para) => {
                    return await getRegionApi({
                      type: filters.type,
                      ...para,
                    });
                  }}
                  OptionComponent={({ option, ...rest }) => {
                    return <ListItem {...rest}>{option.name}</ListItem>;
                  }}
                  onChange={async (changedVal) => {
                    setFilters({
                      ...filters,
                      [findObjectKeyByValue(
                        filters.type,
                        REGIONS_TYPE
                      ).toLowerCase()]: changedVal ? changedVal._id : null,
                    });
                  }}
                  titleKey={"name"}
                  valueKey={"_id"}
                  InputComponent={(params) => (
                    <StyledSearchBar
                      placeholder={
                        "Select " +
                        findObjectKeyByValue(filters.type, REGIONS_TYPE)
                      }
                      {...params}
                      margin="none"
                    />
                  )}
                />
              </PaddingBoxInDesktop>
              {user.data.role !== USER_ROLES.DONOR &&
                user.data.role !== USER_ROLES.NGO && (
                  <PaddingBoxInDesktop
                    mb={1}
                    mt={1}
                    sx={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-end",
                      "*": {
                        justifyContent: "center",
                      },
                    }}
                    pl={3}
                  >
                    <AsyncDropDown
                      lazyFun={async (para) => {
                        return await getDonorsApi({ ...para });
                      }}
                      OptionComponent={({ option, ...rest }) => {
                        return <ListItem {...rest}>{option.name}</ListItem>;
                      }}
                      onChange={async (changedVal) => {
                        setFilters({
                          ...filters,
                          donor: changedVal ? changedVal._id : null,
                        });
                      }}
                      titleKey={"name"}
                      valueKey={"_id"}
                      InputComponent={(params) => (
                        <StyledSearchBar
                          placeholder={"Select Donor"}
                          {...params}
                          margin="none"
                        />
                      )}
                    />
                  </PaddingBoxInDesktop>
                )}
              {user.data.role !== USER_ROLES.NGO && (
                <PaddingBoxInDesktop
                  mb={1}
                  mt={1}
                  sx={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    "*": {
                      justifyContent: "center",
                    },
                  }}
                  pl={3}
                >
                  <AsyncDropDown
                    lazyFun={async (para) => {
                      return await getEventApi({ ...para });
                    }}
                    OptionComponent={({ option, ...rest }) => {
                      return <ListItem {...rest}>{option.name}</ListItem>;
                    }}
                    onChange={async (changedVal) => {
                      setFilters({
                        ...filters,
                        donation_event_id: changedVal ? changedVal._id : null,
                      });
                    }}
                    titleKey={"name"}
                    valueKey={"_id"}
                    InputComponent={(params) => (
                      <StyledSearchBar
                        placeholder={"Select Event"}
                        {...params}
                        margin="none"
                      />
                    )}
                  />
                </PaddingBoxInDesktop>
              )}
              {user.data.role == USER_ROLES.ADMIN && (
                <PaddingBoxInDesktop sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }} mb={1}
                  mt={1} pl={3}>
                  <AsyncDropDown


                    lazyFun={getProductApi}

                    OptionComponent={({ option, ...rest }) => {
                      return <ListItem {...rest}>{option.name}</ListItem >
                    }}
                    onChange={async (changedVal) => {
                      setFilters({ ...filters, product: changedVal ? changedVal._id : null, })
                    }}
                    titleKey={'name'}
                    valueKey={"_id"}
                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Product"} {...params} margin="none" />}
                  />
                </PaddingBoxInDesktop>

              )
              }
            </FiltersBox>
          </Paper>
        )}
        <Box mt={3}>
          <Grid container spacing={2}>
            <TotalHearingImpairedCounts
              totalHearingImpaired={list["total_hearing_impaired"]}
              list={list["hearing_abilty"]}
              loading={loading}
            />
          </Grid>
          <Grid container spacing={2}>
            <HearableCounts
              onBeneficiaryRedirectsClicks={onBeneficiaryRedirectsClicks}
              list={list["hearing_abilty"]}
              loading={loading}
            />
          </Grid>
          <Grid container spacing={2} mt={1}>
            <DonatedDeviceCOunts
              onFollowupsClicks={onFollowupsClicks}
              onBeneficiaryRedirectsClicks={onBeneficiaryRedirectsClicks}
              onVideosClick={onVideosClick}
              onAvgUsageClick={onAvgUsageClick}
              totalFollowup={list["total_followup"]}
              donatedList={list["donated_devices"]}
              loading={loading}
              followUpList={list["follow_up"]}
            />
          </Grid>
          <Grid container spacing={2} mt={1}>
            <AnalysisOfDonatedCountsController
              list={list["hearing_abilty"]}
              speechState={list['speech_state']}
              totalFollowup={list["total_followup"]}
              donatedList={list["donated_devices"]}
              loading={loading}
              followUpList={list["follow_up"]}
            />
          </Grid>
          <Box mt={3}>
            <Paper mt={1} id="map-container">
              <IndiaMap />
            </Paper>
          </Box>
          <Grid container spacing={2} mt={2}>
          {list.hearing_state && <Grid item xs={12} md={6}>
              <HearingStateChartUi
                list={list["hearing_state"]}
                loading={loading}
              />
            </Grid>}
            {list.speech_state &&<Grid item xs={12} md={6}>
              <SpeechStateChartUi
                list={list["speech_state"]}
                loading={loading}
              />
            </Grid>}
            {list.analaysisHearingAndSpeechState && <Grid item xs={12} md={6}>
              <AnalysisOfHearingTest
                list={list['analaysisHearingAndSpeechState']}
                loading={loading}
              />
            </Grid>}
            {list.analaysisHearingAndSpeechState &&
            <Grid item xs={12} md={6}>
              <AnalysisOfSpeechTest
                list={list['analaysisHearingAndSpeechState']}
                loading={loading}
              />
            </Grid>}
            {list.inclusion_state &&<Grid item xs={12} md={6}>
              <InclusionStateChartUi
                list={list["inclusion_state"]}
                loading={loading}
              />
            </Grid>}
            {list.analaysisHearingAndSpeechState &&<Grid item xs={12} md={6}>
              <AnalysisOfInclusionState
                list={list["analaysisHearingAndSpeechState"]}
                loading={loading}
              />
            </Grid>}
          </Grid>
        </Box>
      </DashboardContainer>
      <Button variant="contained" color="primary" onClick={captureScreenshot}>
        Download Report
      </Button>
    </>
  );
};

export default DashboardUi;