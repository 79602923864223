import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  Paper,
  Skeleton,
  Typography,
  styled,
  useTheme,
  ButtonBase,
} from "@mui/material";

import { formatNumberCustomPattern } from "../../../utils/helper";
import { getBeneficiaryVideosCount } from "../../../apis/beneficiary.api";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { callApiAction } from "../../../store/actions/commonAction";

const CountBoxContainer = styled(Box)(({ theme, active }) => ({
  background: active ? theme.palette.primary.main : theme.palette.light.main,
  border: "1px solid " + theme.palette.grey.main,
  borderRadius: theme.shape.borderRadius,
  height: "100%",
  "*": {
    color: active ? theme.palette.light.main + "!important" : "",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const CountBoxComponent = ({
  part,
  count,
  title,
  subtitle,
  active,
  onClick,
  loading,
}) => {
  if (loading) {
    return (
      <Grid item sm={part} xs={12}>
        <Skeleton variant="rounded" width="100%" height="100px"></Skeleton>
      </Grid>
    );
  }

  return (
    <Grid item sm={part} xs={6}>
      <CountBoxContainer
        active={active}
        component={ButtonBase}
        onClick={onClick}
        sx={{ width: "100%" }}
      >
        <Box p={2}>
          <Typography color="primary" variant="h3" align="center">
            {formatNumberCustomPattern(count)}
          </Typography>
          <Typography variant="h6" fontWeight="bold" align="center">
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="caption" fontWeight="bold" align="center">
              {subtitle}
            </Typography>
          )}
        </Box>
      </CountBoxContainer>
    </Grid>
  );
};

const VideoCounts = ({
  data,
  countsLoading,
  filters,
  setFilters,
}) => {


  if (countsLoading)
    return (
      <>
        <Grid container spacing={2}>
          {[0, 1, 2, 3, 4].map((item) => (
            <Grid item xs={6} md={2.4} key={item}>
              <Skeleton
                variant="rounded"
                height="150px"
                width="100%"
              ></Skeleton>
            </Grid>
          ))}
        </Grid>
      </>
    );

  if (data.length > 0)
    return (
      <Grid container spacing={2}>
        {data.map((ele) => (
          <CountBoxComponent
            onClick={() =>
              setFilters({
                ...filters,
                videoStatus: ele.value,
                pageNo: 1,
              })
            }
            part={12 / data.length}
            active={ele.value === filters.videoStatus}
            count={ele.count}
            title={ele.title}
            loading={countsLoading}
          />
        ))}
      </Grid>
    );

  return <></>;
};
export default VideoCounts;
