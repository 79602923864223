import { Box, Paper, Skeleton, Typography, useTheme } from "@mui/material";
import { Bar } from "react-chartjs-2";

const AnalysisOfSpeechTest = ({ list, loading }) => {

    let avgs1s2 = 0;
    let avgs2s3 = 0
    let avgs3s4 = 0
    // console.log("llss",list?.analaysissearingAndSpeecsState[0])
    if (list && Array.isArray(list) && list?.length > 0) {
        for (let item of list) {
            avgs1s2 = item.avgs1s2
            avgs2s3 = item.avgs2s3
            avgs3s4 = item.avgs3s4
        }
    }
    const theme = useTheme();
    const labels = ["S1 to S2", "S2 to S3", "S3 to S4"];
    const options = {
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                display: true,
                color: "black",
                formatter: Math.round,
                anchor: "end",
                offset: -20,
                align: "start"
            }
        },
        scales: {
            x: {
                grid: { display: "" }
            },
            y: {
                min: 0,
                grid: { display: "" }
            }
        }
    };

    const data = {
        labels,
        datasets: [
            {
                label: "Speech Test",
                data:[Math.round(avgs1s2),Math.round(avgs2s3),Math.round(avgs3s4)],
                // data: [list?.averageS1ToS2, list?.averageS2ToS3, list?.averageS3ToS4],
                // data: [list?.analaysisHearingAndSpeechState[0]?.avgs1s2, list?.analaysisHearingAndSpeechState[0]?.avgs2s3, list?.analaysisHearingAndSpeechState[0]?.avgs3s4],
                backgroundColor: theme.palette.primary.main,
                barPercentage: 1,
                base: 0
            }
        ]
    };

    if (loading) {
        return (
            <Paper component={Box} p={2}>
                <Skeleton variant="rounded" height="300px" width="100%" />
            </Paper>
        );
    }



    return (
        <Paper component={Box} p={3}>
            <Typography>Analysis Of Speech Test</Typography>
            <Box mt={1} sx={{ minHeight: "374px" }} className="table">
                <Bar options={options} data={data} style={{ height: "100%" }} />
            </Box>
        </Paper>
    );
};

export default AnalysisOfSpeechTest;
