import { useState } from "react";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../store/actions/modalAction";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import { callApiAction } from "../../../store/actions/commonAction";
import {
  updateBeneficiaryVideoRatingAndTagsApi,
  updateBeneficiaryVideoRatingsApi,
  updateBeneficiaryVideoUrlApi,
  updateVideoStatusApi,
} from "../../../apis/beneficiary.api";
import CustomInput from "../../../components/inputs/CustomInput";
import {
  SNACK_BAR_VARIETNS,
  VIDEOS_STATUS,
  VIDEOS_TAGS,
} from "../../../utils/constants";
import { Autocomplete, Box, Rating, Typography } from "@mui/material";
import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop";
import { StyledSearchBar } from "../../../components/inputs/SearchBar";
import { titleCase } from "../../../utils/helper";
import MessageDilog from "../../../components/MessageDilog";

const RejectedVideoCommentModal = ({
 
  beneficiary_name,
  video_id,
  followup_id,
  video_status,
  date,
  comments,
  // callBack = () => {},
  callback,
  callBack
}) => {
  const modalId = "reject-video";
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
  
    video_id,
    followup_id,
    video_status,
    comments,
    date,
    err: "",
  });

  const newVideoStatus = video_status === VIDEOS_STATUS.MANAGER_APPROVE
      ? VIDEOS_STATUS.ADMIN_REJECT
      : VIDEOS_STATUS.MANAGER_REJECT;

  const onClose = () => {
    dispatch(closeModal(modalId));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(closeModal(modalId));
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await updateVideoStatusApi({
            ...fields,
            comments:fields.comments,
            video_status:newVideoStatus,
            date:new Date().toISOString()
              // VIDEOS_STATUS.MANAGER_REJECT || VIDEOS_STATUS.ADMIN_REJECT,
          }),
        (response) => {
          dispatch(
            callSnackBar(
              "Video Status Updated successfully.",
              SNACK_BAR_VARIETNS.suceess
            )
          );
          setLoading(false);
          onClose(modalId);
          // callBack(fields);
          callBack()
          callback()
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  return (
    <CustomDialog
      id={modalId}
      loading={loading}
      err={fields.err}
      onSubmit={onSubmit}
      title={
        "Are you sure to update beneficiary video status " + beneficiary_name
      }
      onClose={onClose}
      message="On click of Update Status Rejected"
      closeText={"Close"}
      confirmText={"Update"}
    >
      <CustomInput
        disabled={loading}
        value={fields.comments}
        onChange={(e) =>
          setFields({ ...fields, err: "", comments: e.target.value })
        }
        multiline
        rows={3}
        type="text"
        label={"Comment*"}
      />    
    </CustomDialog>

  );
};
export default RejectedVideoCommentModal;
