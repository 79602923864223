import { Autocomplete, Box, Grid, MenuItem, Paper, Skeleton, Typography, styled, useTheme } from "@mui/material"
// import  handleDownloadExcel from "./HandleDownloadExcel"
// import handleDownloadExcel from "./HandleDownloadExcel"
// import HandleDownloadExcel from "./DashboardUi"
import Counts from "./Counts"
import { formatNumberCustomPattern, formatNumberWithCommas } from "../../utils/helper"

const AnalysisOfDonatedCountsController = ({ list,speechState,donatedList,totalFollowup, followUpList, loading,onVideosClick,onFollowupsClicks,onAvgUsageClick,onBeneficiaryRedirectsClicks }) => {
    const theme = useTheme()
 
    const counts = [0, 0, 0]
    const finalData = [0, 0]
    const speechData = [0,0,0,0];
    console.log("List ",list);
    if (list && Array.isArray(list) && list.length > 0) {
        for (let item of list) {
            if (item._id) {
                finalData[0] = item.count
            } else {
                finalData[1] = item.count
            }

        }
    }
   
    if (speechState && Array.isArray(speechState) && speechState.length > 0) {
        for (let item of speechState) {
            if (item._id == 1) {
                speechData[0] = item.count
            } 
            else if(item._id == 2){
                speechData[1] = item.count
            }
            else if(item._id == 3){
                speechData[2] = item.count
            }
            else {
                speechData[3] = item.count
            }

        }
    }
   
    // if (donatedList && Array.isArray(donatedList) && donatedList.length > 0) {
    //     for (let item of donatedList) {
    //         donatedData += item.count

    //     }
    // }
    console.log("Speech Data",speechData);
    if (followUpList && Array.isArray(followUpList) && followUpList.length > 0) {
        const data = followUpList[0]
        console.log("DATA Follow",data)

        counts[0] = data?.count ?? 0
        counts[1] = data?.avg_admin_approved_videos_per_beneficiary ?? 0
        counts[2] = (data?.benificiary_avg_device_usage * data?.benificiary_avg_device_usage_weekly) ?? 0

    }

    const eligible_donated = ((100 * donatedList) /finalData[0] ?? 0);
    const total_followup_done = (totalFollowup/donatedList) ?? 0
    console.log("eleigblae donatedddd0",eligible_donated);
    const sh_impact = (((speechData[2] + speechData[3])/(donatedList))*100) ?? 0
    console.log("SH IMPACTSSSS",sh_impact);
    const impactful_videos = counts[1]/donatedList;
    const iterating_adoption = ((counts[2] * 100)/(36) ?? 0);

    if (loading) {
        return (
            <>
                {[0, 1, 2, 3].map((item) => <Grid item xs={6} md={3} key={item}>

                    <Paper component={Box} p={2}>
                        <Skeleton variant="rounded" height="120px" width="100%" >

                        </Skeleton>
                    </Paper>
                </Grid>)}


            </>)
    }
  
    return <>
        <Grid item xs={6} md={3}>
            <Counts  title="Eligible/Donated"  count={(eligible_donated)} unit={'%'}/>

        </Grid>
    
        <Grid item xs={6} md={3}>
            <Counts  title="Followup Done Per Child" count={(total_followup_done)} />
        </Grid>
        <Grid item xs={6} md={3}>
            <Counts title="S-H Impact" count={(sh_impact ?? 0)}  unit={'%'}/>
        </Grid>
        <Grid item xs={6} md={3}>
            <Counts title="Hearing Adoption" count={iterating_adoption} unit={'%'} />
        </Grid>
        
    </>
}
export default AnalysisOfDonatedCountsController