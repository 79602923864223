import { Box, Paper, Skeleton, Typography, useTheme } from "@mui/material";
import { Bar, Line } from "react-chartjs-2";
import 'chartjs-adapter-date-fns';
import Chart from 'chart.js/auto';

const AnalysisOfHearingTest = ({ list, loading }) => {
    console.log("list", list);
    let avgh1h2 = 0;
    let avgh2h3 = 0;
    let avgh3h4 = 0;

    if (list && Array.isArray(list) && list?.length >= 0) {
        for (let item of list) {
            avgh1h2 = item.avgh1h2;
            avgh2h3 = item.avgh2h3;
            avgh3h4 = item.avgh3h4;
        }
    }
    const theme = useTheme();

    const labels = ["H1 to H2", "H2 to H3", "H3 to H4"];

    const options = {
        // indexAxis: 'y',
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                display: true,
                color: "black",
                formatter:  Math.round,
                // (value, context) => {
                //     console.log("context",context)
                //     console.log("value",value)

                    // if (context.dataIndex >= 0 && context.dataIndex < 4) {  // H1 to H2 data point
                    //     return `${Math.round(value)} days`;
                    // }
                    // return Math.round(value);
                // },
                anchor: "end",
                offset: -20,
                align: "start",
            }
        },
        scales: {
            x: {
                min: 0,
                grid: { display: "" }
            },
            y: {
                grid: { display: "" }
            }
        }
    };

    const data = {
        labels,
        // datasets: [
        //     {
        //         label: "Days",
        //         data: [Math.round(avgh1h2), Math.round(avgh2h3), Math.round(avgh3h4)],
        //         backgroundColor: theme.palette.primary.light,
        //         borderColor: "blue",  
        //         borderWidth: 2,   
        //         pointBorderColor: "blue", 
        //         pointBackgroundColor: "blue",
        //         pointHoverBackgroundColor: theme.palette.secondary.dark,
        //         pointHoverBorderColor: theme.palette.secondary.dark,
        //         barPercentage: 1,
        //         base: 0,
        //     }
        // ]
        datasets: [
            {
                label: "Hearing Test",
                data:[Math.round(avgh1h2),Math.round(avgh2h3),Math.round(avgh3h4)],
                // data: [list?.averageS1ToS2, list?.averageS2ToS3, list?.averageS3ToS4],
                // data: [list?.analaysisHearingAndSpeechState[0]?.avgs1s2, list?.analaysisHearingAndSpeechState[0]?.avgs2s3, list?.analaysisHearingAndSpeechState[0]?.avgs3s4],
                backgroundColor: theme.palette.primary.main,
                barPercentage: 1,
                base: 0
            }
        ]
    };

    if (loading) {
        return (
            <Paper component={Box} p={2}>
                <Skeleton variant="rounded" height="300px" width="100%" />
            </Paper>
        );
    }

    return (
        <Paper component={Box} p={3}>
            <Typography>Analysis Of Hearing Test</Typography>
            <Box mt={1} sx={{ minHeight: "374px" }} className="table">
                <Bar options={options} data={data} style={{ height: "100%" }} />
            </Box>
        </Paper>
    );
};

export default AnalysisOfHearingTest;
